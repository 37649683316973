<template>
    <div class="p-grid">
        <div class="p-col-12 p-md-4">
            <div class="p-field p-fluid">
                        <span class="p-float-label">
                            <Calendar id="date_payment" :showIcon="true" v-model="data.date_payment" dateFormat="dd/mm/yy"
                                      :yearNavigator="true" yearRange="2018:2050"
                                      :class="{'p-invalid': submitted && (!data.date_payment )}" class="p-inputtext-sm" :disabled="block"/>
                            <label for="date_payment">Fecha de pago</label>
                        </span>
                <small class="p-invalid" v-if="submitted && !data.date_payment">La fecha de pago es  requerida</small>
            </div>
        </div>
        <div class="p-col p-col-md-4">
            <div class="p-field">
                        <span class="p-float-label">
                            <InputText id="value_paid" type="number" step="0.01" v-model="data.value_paid"
                                       :class="{'p-invalid': submitted && !data.value_paid}" class="p-inputtext-sm" :disabled="block"/>
                            <label for="value_paid">Monto de pago</label>
                        </span>
                <small class="p-invalid" v-if="submitted && !data.value_paid">El monto de pago es requerido</small>
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-field  p-fluid">
                        <span class="p-float-label">
                            <AutoComplete id="type" :dropdown="true" :multiple="false" field="description"
                                          v-model="data.type" :suggestions="types"
                                          :class="{'p-invalid': submitted && (!data.type )}"
                                          @complete="getCatalog($event, 'types', 'description')" class="p-inputtext-sm" :disabled="block"/>
                            <label for="type">Tipo de pago</label>
                        </span>
                <small class="p-invalid" v-if="submitted && !data.type">El tipo de pago es requerido</small>
            </div>
        </div>
        <div class="p-col-12 p-md-12">
            <hr>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-field">
                <span class="p-float-label">
                    <InputText id="check_number" type="text" v-model="data.check_number" class="p-inputtext-sm"
                               :class="{'p-invalid': submitted && (!data.check_number )  && isCheck }" :disabled="block"/>
                    <label for="check_number">Número de autorización</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.check_number && isCheck">Se requiere el número de autorización</small>
            </div>
            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <Calendar id="date_elaboration" :showIcon="true" v-model="data.date_elaboration" dateFormat="dd/mm/yy" :base-z-index="20000"
                              :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                              :class="{'p-invalid': submitted && (!data.date_elaboration )  && isCheck }" :disabled="block"/>
                    <label for="date_elaboration">Fecha  de elaboración</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.date_elaboration && isCheck">Se requiere la fecha de elaboración</small>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <Calendar id="date_deposit" :showIcon="true" v-model="data.date_deposit" dateFormat="dd/mm/yy"
                              :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                              :class="{'p-invalid': submitted && (!data.date_deposit )  && isCheck }" :disabled="block"/>

                    <label for="date_deposit">Fecha para depositar</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.date_deposit && isCheck">Se requiere la fecha de depósito</small>
            </div>
            <div class="p-field">
                <span class="p-float-label">
                    <InputText id="code" type="text" v-model="data.observation" class="p-inputtext-sm"
                               :class="{'p-invalid': submitted && (!data.observation )  && isCheck }" :disabled="block"/>
                    <label for="code">Observación</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.observation && isCheck">Se requiere la observación</small>
            </div>
        </div>
      <div class="p-col-12 p-md-6">
        <div class="p-field">
                <span class="p-float-label">
                    <InputText id="nro_doc" type="text" v-model="data.nro_doc" class="p-inputtext-sm"
                               :class="{'p-invalid': submitted && (!data.nro_doc )  && isCheck }" :disabled="block"/>
                    <label for="nro_doc">Nro. Recibo</label>
                </span>
          <small class="p-invalid" v-if="submitted && !data.nro_doc && isCheck">Se requiere nro de recibo</small>
        </div>
      </div>
    </div>
</template>

<script>
    import paymentService from '../../service/payment.service'
    import {mapState} from "vuex";
    import catalogueService from "../../../core/service/catalog.service";
    import moment from 'moment';
    export default {
        name: "Payment",
        props: {
            enroll: Object,
            payment: Object,
            block: Boolean,
        },
        emits: ['ok', 'cancel'],
        data() {
            return {
                data: {},
                types: [],
                banks: [],
                submitted: false,
                loading: false,
                cheKey: 'cheque'
            }
        },
        methods: {
            formatData() {
                const params = {...this.data};
                params.bank_id = this.data.bank ? this.data.bank.id : undefined;
                params.type_id = this.data.type.id;
                params.enrollment_id = this.enroll.id;
                params.date_payment = moment(params.date_payment).format("YYYY-MM-DD");
                params.date_deposit = params.date_deposit ?  moment(params.date_payment).format("YYYY-MM-DD"): undefined;
                params.date_elaboration = params.date_elaboration ?  moment(params.date_elaboration).format("YYYY-MM-DD"): undefined;
                return params;
            },
            save() {
                this.submitted = true;
                if (!this.data.date_payment || !this.data.value_paid || !this.data.type ||
                        (this.isCheck && (!this.data.date_deposit || !this.data.observation || !this.data.check_number || !this.data.bank))) {
                    return null;
                }
                return new Promise((resolve, reject) =>  {
                    this.loading = true;
                    const params = this.formatData();
                    paymentService.save(params).then((x) => {
                        this.loading = false;
                        if(x.data.have_fee){
                          this.$toast.add({
                            severity: 'success',
                            detail: x.data.fee_msg,
                            life: this.$utils.toastLifeTime()
                          });
                        }

                        this.$store.dispatch('enroll/setBalance', x.data.payment.balance);
                        resolve(x.data);
                    }).catch((err) => {
                        reject(err);
                    })
                })
            },
            getCatalog(event, route, field) {
                const originalRoute = route;
                route = route === 'types' ? `countries/${this.country.id}/payments/types` : route;
                catalogueService.getCatalog(event, route, field).then(x => {
                    this[originalRoute] = x;
                }).catch(() => {
                    this[originalRoute] = [];
                });
            },
            cancel() {
                this.$emit('cancel')
            }
        },
        mounted() {
            this.data.date_payment = new Date();
            if(this.payment) {
                this.data = this.payment;
                this.data.date_payment = new Date(moment(this.data.date_payment).format());
                this.data.date_deposit = new Date(moment(this.data.date_deposit).format());
                this.data.date_elaboration = new Date(moment(this.data.date_elaboration).format());
            }
        },
        computed: {
            ...mapState('enroll', ['country']),
            isCheck() {
                return this.data.type ? this.data.type.description.toLowerCase() === this.cheKey: false;
            }
        }
    }
</script>

<style scoped lang="scss">

    .p-fieldset {
        margin-top: 15px;
    }

    .p-field {
        padding-top: 15px;
    }

    .p-dialog-footer {
        padding: 0;
        margin-bottom: -15px;
    }

    .p-grid {
        margin-top: 15px;
    }

    .p-inputtext {
        width: 100%;
    }
    .p-autocomplete {
        height: 30px !important;
    }
</style>
